export const QUERY_CLIENTS = `
    clients (sort: ["sort", "name"], limit: -1) {
      id
      name
      status
    }
`
export const QUERY_CUSTOMERS = `
  customers (sort: ["-status", "last_name", "first_name"], limit: -1) {
    id
    status
    last_name
    first_name
    activities {
      minutes
    }
    service {
      id
      name
      code
    }
    client {
      id
      name
    }
    referrers {
      id
      directus_users_id {
        id
        first_name
        last_name
      }
    }
  }
`

export const QUERY_SERVICES = `
    services (sort: ["sort", "name"], limit: -1) {
      id
      name
      status
      code
    }
`

export const QUERY_TASKS = `
    tasks (sort: ["sort", "name"], limit: -1) {
      id
      name
      description
      status
      has_related_customer
      default_client {
        id
      }
      default_service {
        id
      }
    }
`

export const QUERY_USERS = `
    users (sort: ["last_name", "first_name"], filter: {first_name: {_neq: "admin"}}, limit: -1) {
      id
      first_name
      last_name
      status
    }
`
