import Vue from 'vue'
import Vuex from 'vuex'
import gql from 'graphql-tag'

import { QUERY_CLIENTS, QUERY_CUSTOMERS, QUERY_SERVICES, QUERY_TASKS, QUERY_USERS } from './queries'
import { createCollections, createSystem } from './vue-apollo'

const collectionsClient = createCollections.apolloClient
const systemClient = createSystem.apolloClient

Vue.use(Vuex)

async function fetchQuery ({
                             commit,
                             query,
                             client,
                             resultState,
                             loadingState,
                             extract,
                           }) {
  commit(loadingState, true)
  const response = await client.query({
    query,
    fetchPolicy: 'no-cache',
  })
  commit(resultState, extract(response))
  commit(loadingState, false)
}

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    clients: [],
    loadingClients: false,
    customers: [],
    loadingCustomers: false,
    services: [],
    loadingServices: false,
    tasks: [],
    loadingTasks: false,
    users: [],
    loadingUsers: false,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_CLIENTS (state, clients) {
      state.clients = clients
    },
    SET_LOADING_CLIENTS (state, isLoading) {
      state.loadingClients = isLoading
    },
    SET_CUSTOMERS (state, customers) {
      state.customers = customers
    },
    SET_LOADING_CUSTOMERS (state, isLoading) {
      state.loadingCustomers = isLoading
    },
    SET_SERVICES (state, services) {
      state.services = services
    },
    SET_LOADING_SERVICES (state, isLoading) {
      state.loadingServices = isLoading
    },
    SET_TASKS (state, tasks) {
      state.tasks = tasks
    },
    SET_LOADING_TASKS (state, isLoading) {
      state.loadingTasks = isLoading
    },
    SET_USERS (state, users) {
      state.users = users
    },
    SET_LOADING_USERS (state, isLoading) {
      state.loadingUsers = isLoading
    },
  },
  getters: {
    activeClients: state => state.clients.filter(client => client.status === 'published'),
    activeCustomers: state => state.customers.filter(customer => customer.status === 'published'),
    activeServices: state => state.services.filter(services => services.status === 'published'),
    activeTasks: state => state.tasks.filter(tasks => tasks.status === 'published'),
    activeUsers: state => state.users.filter(users => users.status === 'active'),
  },
  actions: {
    async fetch_clients ({ commit }) {
      await fetchQuery({
        query: gql`
          query {
            ${QUERY_CLIENTS}
          }
        `,
        commit,
        client: collectionsClient,
        loadingState: 'SET_LOADING_CLIENTS',
        resultState: 'SET_CLIENTS',
        extract: response => response.data.clients,
      })
    },
    async fetch_customers ({ commit }) {
      return fetchQuery({
        query: gql`
          query {
            ${QUERY_CUSTOMERS}
          }
        `,
        commit,
        client: collectionsClient,
        loadingState: 'SET_LOADING_CUSTOMERS',
        resultState: 'SET_CUSTOMERS',
        extract: response => response.data.customers,
      })
    },
    async fetch_services ({ commit }) {
      await fetchQuery({
        query: gql`
          query {
            ${QUERY_SERVICES}
          }
        `,
        commit,
        client: collectionsClient,
        loadingState: 'SET_LOADING_SERVICES',
        resultState: 'SET_SERVICES',
        extract: response => response.data.services,
      })
    },
    async fetch_tasks ({ commit }) {
      await fetchQuery({
        query: gql`
          query {
            ${QUERY_TASKS}
          }
        `,
        commit,
        client: collectionsClient,
        loadingState: 'SET_LOADING_TASKS',
        resultState: 'SET_TASKS',
        extract: response => response.data.tasks,
      })
    },
    async fetch_users ({ commit }) {
      await fetchQuery({
        query: gql`
          query {
            ${QUERY_USERS}
          }
        `,
        commit,
        client: systemClient,
        loadingState: 'SET_LOADING_USERS',
        resultState: 'SET_USERS',
        extract: response => response.data.users,
      })
    },
    async fetch_collections ({ dispatch }) {
      dispatch('fetch_clients')
      dispatch('fetch_customers')
      dispatch('fetch_services')
      dispatch('fetch_tasks')
    },
  },
})
