<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  import { STORAGE_JWT, STORAGE_JWT_REFRESH } from './auth'

  export default {
    name: 'App',
    mounted () {
      const token = localStorage.getItem(STORAGE_JWT_REFRESH)
      if (token) {
        this.$refreshToken(token)
      } else {
        localStorage.removeItem(STORAGE_JWT)
        this.$goToLogin()
      }
    },
  }
</script>
