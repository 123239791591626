import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueApollo from 'vue-apollo'
import { createCollections, createSystem } from './vue-apollo'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { AuthPlugin } from './auth'
Vue.config.productionTip = false

const collections = createCollections.apolloClient
const system = createSystem.apolloClient

// Create vue apollo provider
const apolloProvider = new VueApollo({
  clients: {
    collections,
    system,
  },
  defaultClient: collections,
})

Vue.use(VueApollo)
Vue.use(new AuthPlugin())

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
