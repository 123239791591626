import gql from 'graphql-tag'

export const STORAGE_JWT = 'jwt'
export const STORAGE_JWT_REFRESH = 'jwt-refresh'

const GQL_AUTH = gql`mutation login ($email: String!, $password: String!) {
  auth_login(email: $email, password: $password) {
    access_token
    refresh_token
  }
}`

const GQL_REFRESH = gql`
  mutation refresh ($refresh_token: String!) {
    auth_refresh(refresh_token: $refresh_token) {
      access_token
      refresh_token
    }
  }`

function handleAuthError (error) {
  const code = error.networkError?.statusCode
  if (code === 401) {
    localStorage.removeItem(STORAGE_JWT)
    localStorage.removeItem(STORAGE_JWT_REFRESH)
    this.$goToLogin()
  }
}

function refreshToken (refreshToken) {
  if (this.$refreshTokenTimeout !== null) {
    clearTimeout(this.$refreshTokenTimeout)
  }
  this.$refreshTokenTimeout = setTimeout(() => {
    this.$apollo.mutate({
      mutation: GQL_REFRESH,
      client: 'system',
      variables: {
        refresh_token: refreshToken,
      },
    }).then((response) => {
      this.$refreshTokenTimeout = null
      localStorage.setItem(STORAGE_JWT, response.data.auth_refresh.access_token)
      localStorage.setItem(STORAGE_JWT_REFRESH, response.data.auth_refresh.refresh_token)
      this.$refreshToken(response.data.auth_refresh.refresh_token)
    }).catch(() => {
      this.$goToLogin()
    })
  }, 10 * 60 * 1000)
}

function login (email, password) {
  localStorage.removeItem(STORAGE_JWT)
  localStorage.removeItem(STORAGE_JWT_REFRESH)
  return this.$apollo.mutate({
    mutation: GQL_AUTH,
    client: 'system',
    variables: {
      email,
      password,
    },
  }).then((response) => {
    localStorage.setItem(STORAGE_JWT, response.data.auth_login.access_token)
    localStorage.setItem(STORAGE_JWT_REFRESH, response.data.auth_login.refresh_token)
    this.$store.dispatch('fetch_services').catch(error => this.$handleAuthError(error))
    this.$store.dispatch('fetch_clients').catch(error => this.$handleAuthError(error))
    fetch('https://api.chronophage.xenote.ch/users/me', {
      headers: {
        Authorization: 'Bearer ' + response.data.auth_login.access_token,
      },
    }).then(body => body.json()).then(me => {
      localStorage.setItem('me', me.data.id)
    })
    this.$refreshToken(response.data.auth_login.refresh_token)
    this.$router.push({ name: 'Tableau de Bord' })
  })
}

export class AuthPlugin {
  install (Vue) {
    Vue.prototype.$goToLogin = function () {
      this.$router.push({ name: 'Login' })
    }
    Vue.prototype.$handleAuthError = handleAuthError
    Vue.prototype.$refreshToken = refreshToken
    Vue.prototype.$login = login
  }
}
