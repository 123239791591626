import Vue from 'vue'
import Router from 'vue-router'
import { STORAGE_JWT } from './auth'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/Login'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Tableau de Bord',
          path: '',
          component: () => import('@/views/dashboard/pages/Dashboard'),
        },
        {
          name: 'Mes Activités',
          path: 'my-activities',
          component: () => import('@/views/dashboard/pages/MyActivities'),
        },
        {
          name: 'Bénéficiaires',
          path: 'customers',
          component: () => import('@/views/dashboard/pages/Customers'),
        },
        {
          name: 'Mesures',
          path: 'services',
          component: () => import('@/views/dashboard/pages/Services'),
        },
        {
          name: 'Tâches',
          path: 'tasks',
          component: () => import('@/views/dashboard/pages/Tasks'),
        },
        {
          name: 'Statistiques',
          path: 'statistics',
          component: () => import('@/views/dashboard/pages/Statistics'),
        },
        {
          name: 'Facturation',
          path: 'billing',
          component: () => import('@/views/dashboard/pages/Billing'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem(STORAGE_JWT)
  if (to.name === 'Login' && authToken) {
    next({ name: 'Dashboard' })
  } else if (to.name !== 'Login' && !authToken) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
